import * as React from 'react';
import { array, boolean, object, number, string } from 'yup';
import { TaxExemption } from 'bundles/App/pages/Account/TaxExemptions/TaxExemptionsPage';
import Modal from 'styleguide/components/Modal/Modal';
import { Field, FieldArray, Form, Formik } from 'formik';
import { updateFormikStatus } from 'libs/utils/updateFormik';
import { Status } from 'libs/utils/api/types';
import startCase from 'lodash-es/startCase';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { addressSchema } from 'utils/schema';
import { AddressState, emptyAddress } from 'bundles/App/pages/Account/Addresses/types';
import { createTaxExemption, getNexusRegions, updateTaxExemption } from 'api/account/taxExemptions';
import Grid from 'styleguide/components/Grid/Grid';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import Label from 'styleguide/components/Formik/Label/Label';
import { FormikFieldWrapper } from 'styleguide/components/Formik';
import Action from 'styleguide/components/Action/Action';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';
import { A, Button } from 'styleguide/components';
import SimpleUploader from 'styleguide/components/Formik/SimpleUploader/SimpleUploader';
import AppContext from 'contexts/AppContext/AppContext';
import Span from 'styleguide/components/Span/Span';
import omit from 'lodash-es/omit';
import AddressBook from 'app/styleguide/components/Formik/AddressBook/AddressBook';

interface Props {
  taxExemption: TaxExemption;
  setTaxExemption: (taxExemption: TaxExemption) => void;
  onClose: () => void;
}

const TaxExemptionsFormModal = ({ taxExemption, setTaxExemption, onClose }: Props) => {
  const { currentUser } = React.useContext(UserContext);
  const addresses = currentUser.addresses ? currentUser.addresses : [];
  const [statesWithNexus, setStatesWithNexus] = React.useState<AddressState[]>([]);
  const taxExemptionTypes = ['wholesale', 'government'];
  const {
    store: { states },
  } = React.useContext(AppContext);
  const [newAddress, setNewAddress] = React.useState(addresses.length === 0);

  React.useEffect(() => {
    getNexusRegions().then(res => {
      if (res.status === Status.Ok) {
        const nexusRegions = res.payload.nexusRegions ? res.payload.nexusRegions : [];
        setStatesWithNexus(states.filter(s => nexusRegions.some(n => s.abbr === n)));
      }
    });
  }, []);

  const taxExemptionFormSchema = object().shape({
    taxExemptionType: string().required(),
    addressId: number().nullable(),
    addressAttributes: addressSchema,
    taxjarExemptRegionsAttributes: array().of(
      object().shape({
        id: number(),
        stateId: number().when('id', {
          is: val => val !== null,
          then: schema => schema.required('State Required'),
        }),
        taxExemptionDocument: string().when('id', {
          is: val => val !== null,
          then: schema => schema.required('Tax Exemption Document Required'),
        }),
        _destroy: boolean().nullable(),
        fileName: string().nullable(),
      }),
    ),
  });

  const buildInitialValues = () => ({
    taxExemptionType: taxExemption ? taxExemption.taxExemptionType : taxExemptionTypes[0],
    addressId: taxExemption ? taxExemption.address.id : addresses?.[0]?.id,
    addressAttributes: taxExemption
      ? omit(taxExemption.address, 'id', 'description', 'state', 'country')
      : emptyAddress,
    taxjarExemptRegionsAttributes: taxExemption
      ? taxExemption.exemptRegions.map(er => ({
          id: er.id,
          stateId: er.state.id,
          _destroy: false,
          taxExemptionDocument: er.taxExemptionDocument.url,
          fileName: er.taxExemptionDocument.name,
        }))
      : [],
  });

  return (
    <Modal hideScroll shown onClose={() => onClose()}>
      <Formik
        initialValues={buildInitialValues()}
        onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
          if (taxExemption) {
            const taxExemptionAttributes = {
              ...values,
              taxjarExemptRegionsAttributes: values.taxjarExemptRegionsAttributes.map(elem => {
                if (elem.id) {
                  return { id: elem.id, _destroy: elem._destroy };
                }
                return elem;
              }),
            };

            updateTaxExemption(taxExemptionAttributes).then(res => {
              updateFormikStatus(res, setStatus, setSubmitting, setErrors);
              if (res.status === Status.Ok) {
                setTaxExemption(res.payload.taxExemption);
                onClose();
              }
            });
          } else {
            createTaxExemption(values).then(res => {
              updateFormikStatus(res, setStatus, setSubmitting, setErrors);
              if (res.status === Status.Ok) {
                setTaxExemption(res.payload.taxExemption);
                onClose();
              }
            });
          }
        }}
        validationSchema={taxExemptionFormSchema}
      >
        {formikProps => (
          <Form className="w-full -md:ml-5 -md:mr-5">
            <Grid.Row className="mt-1">
              <Grid.Col sm={12} md={6}>
                <FormikFieldWrapper
                  name="taxExemptionType"
                  componentType="combobox"
                  labelComponent="Tax Exemption Type"
                  options={taxExemptionTypes.map(s => ({
                    key: s,
                    name: startCase(s),
                  }))}
                  wrapperClassName="mt-1 mb-6"
                />
                <AddressBook
                  newAddress={newAddress}
                  setNewAddress={setNewAddress}
                  addressType=""
                  heading="Business Address"
                  addresses={addresses}
                  addressFormProps={{ poBoxWarning: false }}
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <FieldArray
                  name="taxjarExemptRegionsAttributes"
                  render={arrayHelpers => (
                    <div className="flex flex-col my-5">
                      <Button
                        className="self-center"
                        admin
                        type="button"
                        color="blue"
                        onClick={() =>
                          arrayHelpers.push({ stateId: statesWithNexus[0].id, taxExemptionDocument: null })
                        }
                      >
                        Add Exempt State
                      </Button>
                      {formikProps.values.taxjarExemptRegionsAttributes.length > 0 ? (
                        formikProps.values.taxjarExemptRegionsAttributes.map(
                          (exemptRegion, index: number) => (
                            <Grid.Row className="mt-1 mb-0 border-b border-gray-200 py-2">
                              <Field type="hidden" name={`taxjarExemptRegionsAttributes.${index}.id`} />
                              {exemptRegion.id && !exemptRegion._destroy && (
                                <>
                                  <Grid.Col sm={3} md={3} className="pr-1">
                                    <Span>{states.find(s => s.id === exemptRegion.stateId).name}</Span>
                                  </Grid.Col>
                                  <Grid.Col
                                    sm={8}
                                    md={8}
                                    className="overflow-hidden whitespace-nowrap text-ellipsis"
                                  >
                                    <A
                                      href={exemptRegion.taxExemptionDocument}
                                      color="blue"
                                      underline="always"
                                    >
                                      {exemptRegion.fileName}
                                    </A>
                                  </Grid.Col>
                                  <Grid.Col sm={1} md={1}>
                                    <Action
                                      icon={FaTrashAlt}
                                      action="destroy"
                                      onClick={() =>
                                        formikProps.setFieldValue(
                                          `taxjarExemptRegionsAttributes.${index}._destroy`,
                                          true,
                                        )
                                      }
                                      hoverColor="red"
                                    />
                                  </Grid.Col>
                                </>
                              )}
                              {!exemptRegion.id && (
                                <>
                                  <FormikFieldWrapper
                                    name={`taxjarExemptRegionsAttributes.${index}.stateId`}
                                    componentType="combobox"
                                    labelComponent="State"
                                    options={statesWithNexus.map(s => ({
                                      key: s.id,
                                      name: s.name,
                                    }))}
                                  />
                                  <Grid.Col sm={12} md={6}>
                                    <FieldWrapper
                                      Label={<Label placement="left">Tax Exempt Document</Label>}
                                      Input={
                                        <SimpleUploader
                                          onSuccess={value =>
                                            formikProps.setFieldValue(
                                              `taxjarExemptRegionsAttributes.${index}.taxExemptionDocument`,
                                              value,
                                            )
                                          }
                                        />
                                      }
                                    />
                                  </Grid.Col>
                                  <Grid.Col sm={12} md={1}>
                                    <Action
                                      icon={FaTrashAlt}
                                      action="destroy"
                                      onClick={() => arrayHelpers.remove(index)}
                                      hoverColor="red"
                                    />
                                  </Grid.Col>
                                </>
                              )}
                            </Grid.Row>
                          ),
                        )
                      ) : (
                        <div>No Exempt states</div>
                      )}
                    </div>
                  )}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className="text-center my-5">
                <Button
                  admin
                  type="button"
                  color="blue"
                  onClick={() => formikProps.submitForm()}
                  disabled={formikProps.isSubmitting}
                >
                  Submit Tax Exemptions
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TaxExemptionsFormModal;
